.myPage {
  margin: 20px 20px;
}

.label {
  font-size: 20px;
  margin-bottom: 27px;
}

.myPageContainer {
  display: flex;
}

.content {
  display: flex;
}

.contentRight {
  margin: 44px 54px;
  width: 80%;
}

.passwordContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 700px;
  margin: 90px auto;
}

.passwordInputBox {
  display: flex;
}

.passwordLabel {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 24px;
}

.passwordInput {
  display: inline-block;
  border: 1px solid #ccc;
  width: 100%;
  height: 52px;
  padding: 15px 15px;
  margin: 5px 0;
}

.passwordButton {
  display: flex;
  margin-top: 56px;
  gap: 20px;
}

.buttonLineBlue {
  border: none;
  font-size: 16px;
  color: #346aff;
  border: 1px solid #346aff;
}

.buttonBlue {
  border: none;
  font-size: 16px;
  background: #346aff;
  color: white;
}
