.authTemplateBlock {
  width: 460px;
  height: 673.2px;
  margin: 0 auto;
}
.borderLine {
  width: 100vw;
  left: 0;
  height: 1px;
  background: #dfe3e8;
}

.whiteBox {
  background-color: '#fffff';
  padding: 16px;
  font-weight: 700;
}

.logoArea {
  display: block;
  text-align: center;
}

/* authform */

.authFormBlock {
  margin: 0 auto;
  margin-top: 40px;
}

.label {
  padding: 10px 0;
  font-size: 16px;
}

.styledInput {
  display: inline-block;
  border: 1px solid #ccc;
  width: 100%;
  height: 52px;
  padding: 15px 15px;
  margin: 5px 0;
}

.authButton {
  margin: 20px 0;
}

.errorMsg {
  color: red;
}
