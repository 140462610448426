.listbox {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebebeb;
  border-top: 1px solid #ebebeb;
  padding: 19px 0 20px 20px;
}

.listboxContainer {
  margin: 30px 0 40px;
}

.listLabel {
  font-size: 24px;
  color: #5b89ff;
  margin-bottom: 18px;
}

.listboxLabel {
  font-size: 18px;
  width: 232px;
}

.listboxContent {
  font-size: 18px;
}

.listboxButton {
  display: flex;
}

/* a tag */
.goPage {
  background-color: #d9d9d9;
  padding: 8px 32px;
  text-decoration: none;
  cursor: pointer;
}
