.detailWrapper {
  max-width: 1200px;
  margin: 36px auto;
}

.detailContent {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 100px 20px;
}

.detailcontent-image {
  width: 300px;
}

.productName {
  padding-bottom: 20px;
  color: black;
}

.price {
  color: red;
  font-size: 20px;
}

.inputPrice {
  width: 142px;
  height: 30px;
  margin: 30px auto;
}

.whiteButton {
  border: 1px solid black;
  color: black;
  background-color: white;
}

.menubar {
  margin: 0;
  padding: 0;
  position: sticky;
  top: 0;
  background-color: white;
}

.menu1,
.menu2,
.menu3 {
  display: inline-block;
  text-align: center;
  padding: 15px 20px 14px;
  width: 33%;
  border: 1px solid black;
  color: black;
  margin: 0;
  text-decoration: none;
}

.reviewList {
  margin: 20px auto;
}

.reviewPeople {
  display: flex;
  align-items: center;
  gap: 10px;
}

.reviewStar {
  display: flex;
  align-items: center;
}

.reviewContent {
  color: black;
  margin: 20px auto;
}

.date {
  margin: 20px auto;
  color: black;
}

.imageArea {
  display: flex;
  gap: 20px;
  white-space: nowrap;
  overflow: scroll;
}

.buttonArea {
  display: flex;
  gap: 20px;
}

.section3Box {
  margin-bottom: 200px;
}

.categoryBtn {
  display: flex;
  margin: 40px 300px 40px 0;
  gap: 10px;
}

/* 동그란 버튼 */
.buttonCircle {
  color: white;
  background-color: #346aff;
  border-radius: 20px;
  border: none;
  font-size: 15px;
}

.buttonCircle.white {
  color: #346aff;
  background-color: white;
  border: 1px solid#346aff;
}

.reviewLitag {
  display: flex;
  justify-content: space-between;
  padding: 18px 0 18px;
}

.reviewLitag-button {
  width: 20px;
}

.reviewListHiddenBox {
  height: 400px;
  background-color: #d9d9d9;
}

.hiddenBoxTop {
  padding: 20px 100px;
  background-color: #f7f7f7;
  margin: 0 24px 20px;
  height: 40%;
  font-size: 14px;
}

.hiddenBoxBottom {
  padding: 20px 100px;
  background-color: #c7c7c7;
  margin: 0 24px;
  height: 50%;
  font-size: 14px;
}

/* TABLET */
@media (min-width: 768px) and (max-width: 1199px) {
  .detailWrapper {
    margin: 40px 100px;
  }

  .detailContent {
    display: block;
  }
}

/* Mobile */
@media (min-width: 365px) and (max-width: 767px) {
  .detailWrapper {
    margin: 80px 20px;
  }

  .detailContent {
    display: block;
  }
}
