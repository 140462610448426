hr {
  margin: 0;
}

.myPage {
  margin: 20px 20px;
}

.label {
  font-size: 20px;
  margin-bottom: 27px;
}

.myPageContainer {
  display: flex;
}

.menuList {
  list-style: none;
  display: inline-block;
  padding-left: 20px;
}

.contentRight {
  margin: 44px 54px;
}

.menuList li a {
  text-decoration: none;
  font-size: 20px;
  margin: 10px 0;
}

li a:visited {
  color: black;
}

.textBlue {
  color: #5b89ff;
}

.content {
  display: flex;
}

.borderBox {
  background-color: #d9d9d9;
  width: 235px;
  height: 1286px;
}

/* input 영역 */
.inputBoxArea {
  position: relative;
  width: 460px;
  height: 50px;
  padding-left: 59px;
  margin-bottom: 22px;
}

.inputBoxIcon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 59px;
  height: 50px;
  background-color: #fafafa;
  border: 1px solid #cccccc;
  left: 0;
}

.inputBoxArea input {
  border: 1px solid #cccccc;
  outline: none;
}

.inputBoxArea img {
  position: absolute;
}

.defaultDelival {
  display: flex;
}

.defaultDelival input {
  width: 20px;
}

.bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 173px 0;
  gap: 32px;
}

.buttonFillWhite {
  width: 100%;
  height: 50px;
  font-size: 20px;
  color: #5b89ff;
  border-color: #5b89ff;
}

.buttonFillBlue {
  width: 100%;
  height: 50px;
  font-size: 20px;
  background-color: #5b89ff;
  color: #fff;
}
