.myPage {
  margin: 20px 20px;
}

.label {
  font-size: 20px;
  margin: 27px 0 27px;
}

.myPageContainer {
  display: flex;
}

.menuList {
  list-style: none;
  display: inline-block;
  padding-left: 20px;
}

.content {
  display: flex;
}
