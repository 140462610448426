.myPage {
  margin: 20px 20px;
}

.label {
  font-size: 20px;
  margin-bottom: 27px;
}

.myPageContainer {
  display: flex;
}

.myPageSection {
  margin: 53px 0 24px;
}

.content {
  display: flex;
}

.myPageContent {
  margin: 44px 54px;
  width: 80%;
}
