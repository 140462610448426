.main-container {
  max-width: 1200px;
  margin: 36px auto;
}

.label {
  font-size: 24px;
}

.text-blue {
  color: #5b89ff;
}

/* TABLET */
@media (min-width: 768px) and (max-width: 1199px) {
  .main-container {
    margin: 40px 100px;
  }
}

/* Mobile */
@media (min-width: 365px) and (max-width: 767px) {
  .main-container {
    margin: 80px 20px;
  }
}
