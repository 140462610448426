.borderBox {
  background-color: #d9d9d9;
  height: 1286px;
  white-space: nowrap;
}

.menuList {
  list-style: none;
  display: inline-block;
  padding: 20px 20px 0;
}

.menuText {
  font-size: 16px;
}

.menuList li a {
  text-decoration: none;
  font-size: 16px;
  margin: 10px 0;
}

a:hover {
  color: #5b89ff;
}

li a:visited {
  color: black;
}

.textBlue {
  color: #5b89ff;
}

/* Mobile */
@media (min-width: 365px) and (max-width: 767px) {
  .menuList {
    padding: 8px 0px 0 8px;
  }

  .menuList li a {
    font-size: 12px;
  }
}
