hr {
  margin: 0;
}

.myPage {
  margin: 20px 20px;
}

.label {
  font-size: 20px;
  margin-bottom: 27px;
}

.myPageContainer {
  display: flex;
}

.contentRight {
  margin: 44px 54px;
  min-width: 600px;
}

.table {
  border-collapse: collapse;
}

.table > tr {
  height: 66px;
}

.table > tr td {
  vertical-align: middle;
}

.table > tr td:first-child {
  width: 100px;
}

.tableInline {
  display: flex;
}

.tableRowspan {
  display: flex;
  align-items: center;
  gap: 50px;
}

.table button,
.peopleout button,
.out button {
  background-color: #d9d9d9;
  border: none;
  padding: 12.5px 0;
  outline: none;
  cursor: pointer;
  width: 100px;
  margin-left: 24px;
}

.myPageInput {
  width: 285px;
  height: 41px;
}

.center {
  text-align: center;
}

.peopleout {
  text-align: right;
}
