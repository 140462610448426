header ul {
  display: inline-block;
  padding: 0;
  margin: 0 40px;
}

header li {
  display: inline-block;
  color: black;
  margin-left: 10px;
}

header a {
  text-decoration: none;
  color: black;
}

.topBar {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  height: 32px;
  background-color: #f0f0f0;
}

.header-main {
  gap: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
}

.category-box {
  position: relative;
}

.search-box {
  display: flex;
  align-items: center;
  position: relative;
  width: 518px;
  height: 46px;
}

.search-btn {
  position: absolute;
  width: 30px;
  margin-left: 10px;
}

.input-area {
  padding: 14px 35px;
  border: 1px solid gray;
  outline: none;
}

/* TABLET */
@media (min-width: 768px) and (max-width: 1199px) {
}

/* Mobile */
@media (min-width: 365px) and (max-width: 767px) {
}
