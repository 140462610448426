.pictureAttach {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-top: 36px;
}
.reviewPageButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 372px;
  height: 62px;
  color: #5b89ff;
  border-color: #5b89ff;
  background-color: #dcdcdc;
  border: none;
  text-align: center;
  margin-bottom: 20px;
}

.count {
  padding-left: 20px;
}

.paragraph {
  display: flex;
  justify-content: right;
  padding: 54px 50px 0;
}

.fileInput {
  display: none;
}

.pictureArea {
  display: flex;
}

.pictureBox {
  display: flex;
  position: relative;
  margin: 10px 10px;
  width: 150px;
  height: 150px;
  border: 1px solid #4f4f4f;
}

.cancelButton {
  background-image: url('../../assets/icon/image_cancleBtn.svg');
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  background-position: center;
  position: absolute;
  left: 70%;
  top: 10%;
  width: 30px;
  height: 30px;
  border: none;
  cursor: pointer;
}
