hr {
  margin: 0;
}

.myPage {
  margin: 20px 20px;
}

.label {
  font-size: 20px;
  margin-bottom: 27px;
}

.contentRight {
  margin: 44px 54px;
}

.menuList {
  list-style: none;
  display: inline-block;
  padding-left: 20px;
}

.menuList li a {
  text-decoration: none;
  font-size: 20px;
  margin: 10px 0;
}

li a:visited {
  color: black;
}

.textBlue {
  color: #5b89ff;
}

.myPageContainer {
  display: flex;
}

.borderBox {
  background-color: #d9d9d9;
  width: 235px;
  height: 1286px;
}

.section2,
.section3 {
  margin-top: 82px;
}

.orderInfo {
  font-size: 18px;
}

.orderBox {
  display: flex;
  align-items: center;
  height: 270px;
  width: 100%;
  border: 1px solid black;
  padding: 26px 39px;
}

.orderBoxInfo {
  display: flex;
  flex-direction: column;
  margin: 60px 0 36px 66px;
}

.productName {
  font-size: 18px;
}

.productPrice {
  margin: 13px 0 17px;
  font-size: 30px;
  color: #ff0000;
}

.productCt {
  font-size: 30px;
}

.table {
  border-collapse: collapse;
  margin: 30px 19px;
  width: 100%;
}

.table > tr {
  width: 100%;
  height: 66px;
}

.table > tr td {
  border-bottom: 1px solid black;
}

.table > tr td:first-child {
  width: 222px;
}
