.mainContainer {
  margin: 83px 150px;
}

.label {
  font-size: 30px;
  margin-bottom: 27px;
}

.orderInfo {
  font-size: 18px;
}

.orderBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 270px;
  border: 1px solid black;
  padding: 26px 100px;
  margin: 28px 50px;
}

.orderBoxInfo {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin: 60px 36px;
}

.productName {
  font-size: 18px;
}

.productPrice {
  margin: 13px 0 17px;
  font-size: 30px;
  color: #ff0000;
}

.productCt {
  font-size: 30px;
}

.qaContent {
  margin: 55px 30px;
  width: 800px;
  margin: auto;
}

.selectBox {
  background-color: #d9d9d9;
  height: 52px;
  width: 100%;
}

.qaContentBox {
  display: flex;
}

.qaContentBoxLeft {
  width: 30%;
}

.qaContentBoxRight {
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.qaLabel {
  background-color: #f1f1f1;
  height: 30px;
  text-align: center;
}

.textarea {
  width: 560px;
  height: 244px;
  background-color: #eaeaea;
}

.textarea::placeholder {
  margin: 30px 27px 0;
}
.qaFileAttachBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: #f1f1f1;
  height: 170px;
}

.pictureAttach {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.count {
  padding-left: 20px;
}

.paragraph {
  font-size: 13px;
  position: absolute;
  top: 146px;
  right: 20px;
}

.buttonFillBlue {
  width: 245px;
  height: 50px;
  font-size: 20px;
  background-color: #5b89ff;
  color: #fff;
}

.buttonArea {
  margin: auto;
}
