* {
  box-sizing: border-box;
}

body {
  margin: 0;
  /* background-color: #000000; */
  color: #000000;
}

h1,
span {
  color: black;
}

a {
  display: inline-block;
}

ul.pageList {
  margin: 100px auto;
  width: 500px;
  padding: 0;
}

input {
  width: 100%;
  height: 100%;
  padding: 15px 15px;
}

.wrap {
  margin: 0 auto;
  padding: 12px;
  max-width: 1080px;
  width: 100%;
}

button:disabled {
  background-color: #000051;
}

/* button 기본 css */
.button {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  cursor: pointer;
  outline: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 20px;
  background-color: #fff;
  width: 100%;
}

/* a태그 보라색삭제 */
a {
  text-decoration: none;
  color: black;
}
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
a:focus {
  text-decoration: none;
}
a:hover,
a:active {
  text-decoration: none;
}

img {
  width: 100%;
}
