.myPage {
  margin: 20px 20px;
}

.label {
  font-size: 20px;
  margin: 27px 0 27px;
}

.myPageContainer {
  display: flex;
}

.menuList {
  list-style: none;
  display: inline-block;
  padding-left: 20px;
}

.content {
  display: flex;
}

.contentRight {
  margin: 44px 54px;
}

.label {
  font-size: 20px;
}

.sectionInfo {
  display: flex;
  gap: 14px;
}

.sectionDt {
  padding-bottom: 24px;
}

.borderLine {
  padding-bottom: 27px;
  border-bottom: 1px solid rgb(123, 123, 123);
}

.textarea {
  width: 600px;
  height: 244px;
  padding: 24px;
  background-color: #eaeaea;
}

.textarea::placeholder {
  margin: 30px 27px 0;
}

.bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
  gap: 32px;
}

.buttonFillWhite {
  width: 245px;
  height: 50px;
  font-size: 20px;
  color: #5b89ff;
  border-color: #5b89ff;
  border: none;
}

.buttonFillBlue {
  width: 245px;
  height: 50px;
  font-size: 20px;
  background-color: #5b89ff;
  color: #fff;
  border: none;
}
